@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600&display=swap');


:root {
  --bg-color: #EEF7FE;
  --accent: #001A32;
  --accent2: #0080F6;
  --accent3: #f0de36;
  --darkaccent2: #a50f0e;
  --black: #000;
  --bs-gutter-x: 3rem;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
.h1,
.h2 {
  font-size: 3rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}
img {
  max-width: 100%;
}
p {
  font-size: 1rem;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  //@media (min-width:1400px) {
  //max-width: 1320px;
  //}
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}
.mainWrapper {
  header {
    padding: 0.5rem 0;
    display: none;
    @media (min-width: 992px) {
      padding: 1rem 0;
    }
    background-color: #fff;
    position: relative;
    z-index: 2;
    .logo-wrapper {
      a {
        img {
          max-width: 2rem;
          @media (min-width: 992px) {
            max-width: 3rem;
          }
        }
      }
    }
  }
  .hero-section {
    padding: 0 0.65rem;
    .container {
      max-width: 100%;
      background-color: var(--bg-color);
      border-radius: 20px 20px 20px 20px;
      padding: 0px 0.625rem 0px 0.625rem;
      @media (min-width:768px) {
        padding: 0px 1rem 0px 1rem;
      }
      @media (min-width:1024px) {
        padding: 0px 2.5rem 0px 2.5rem;
      }
      .row {
        padding: 80px 0px 0px 0px;
        max-width: 958px;
        margin: 0 auto;
        @media (min-width:1024px) {
          min-height: 60vh;
        }
        .span-headline-wrapper {
          width: 100%;
          flex: 0 0 auto;
          h3 {
            text-align: center;
            margin-bottom: 1.25rem;
            span {
              margin: 0 auto;
              display: inline;
              font-size: .93rem;
              line-height: 1.38rem;
              padding: 0.31rem 1.125rem;
              font-weight: 400;
              background-color: white;
              border-radius: 50px;
            }
          }
        }
        .headline-wrapper {
          width: 100%;
          flex: 0 0 auto;
          text-align: center;
          h1 {
            color: var(--accent);
            line-height: 2rem;
            font-size: 1.8rem;
            @media (min-width: 768px) {
              font-size: 3rem;
              line-height: 3.9rem;
            }
          }
        }
        .sub-hero-section {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .left-side {
            flex: 0 0 auto;
            width: 100%;
            text-align: center;
            p {
              margin: 1.25rem 0;
              font-size: 1rem;
              color: #0101018F;
              @media (min-width:768px) {
                font-size: 1.125rem;
                line-height: 1.9125rem;
                max-width: 577px;
                margin: 1.25rem auto;
              }
            }
            .desktop-cta {
                text-align: center;
                display: inline;
                flex: 0 0 auto;
                width: 100%;
                margin: 1rem 0;
                a {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  text-decoration: none;
                  font-size: 1rem;
                  font-weight: 700;
                  background-color: var(--accent2);
                  color: #fff;
                  padding: 17px 38px 17px 38px;
                  border-radius: 8px;
                  width: 80%;
                  img {
                    max-width: 40px;
                    margin-left: 0.5rem;
                  }
                  transition: all 0.2s ease-in-out;
                  &:hover,
                  &:focus {
                    background-color: var(--darkaccent2);
                  }
                  @media (min-width:768px) {
                    font-size: 1.125rem;
                    line-height: 1.9125rem;
                    width: 50%;
                  }
                }
                span {
                  display: block;
                  margin-top: 1rem;
                  margin-bottom: 1.25rem;
                  font-size: 1rem;
                }
            }
          }
          .right-side {
            flex: 0 0 auto;
            text-align: center;
            width: 100%;
            img {
              max-width: 100%;
              display: block;
              @media (min-width:768px) {
                max-width: 50%;
                margin: 0 auto;
              }
            }
          }
        }
        .left-side {
          flex: 0 0 auto;
          width: 100%;
          .question {
            position: relative;
            background-color: #fff;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            padding: 2rem 1.5rem;
            border-radius: 18px;
            text-align: center;
            margin: 1rem auto;
            max-width: 580px;
            width: 100%;
            h2 {
              color: var(--accent);
              font-size: 1.4rem;
              line-height: 120%;
              font-weight: 500;
            }
            a {
              display: block;
              margin: 1rem 0;
              background-color: var(--accent2);
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            }
            input {
              display: block;
              width: 100%;
              margin: 1rem 0;
              padding: 1rem;
              border-radius: 8px;
              border: 1px solid #e5e5e5;
              font-size: 1rem;
              -moz-appearance: textfield;
              appearance: textfield;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          .congrats {
            margin: 1rem auto;
            max-width: 580px;
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            padding: 1.25rem;
            border-radius: 18px;
            text-align: center;
            h2 {
              color: var(--accent);
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              line-height: 120%;
            }
            a {
              display: block;
              margin: 1rem 0;
              background-color: var(--accent2);
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              transition: all 0.2s ease-in-out;
              &:hover,
              &:focus {
                background-color: var(--darkaccent2);
              }
            }
            p {
              span {
                color: #0d1282;
                font-weight: bold;
              }
            }
            img {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  .badges-section {
    padding: 1.875rem 0 0 0;
    @media (min-width:992px) {
      padding: 5rem 0 0 0;
    }
    .container {
      .row {
        .badge-headline-wrapper {
          margin-bottom: 1.25rem;
          width: 100%;
          flex:0 0 auto;
          display: none;
          @media (min-width:768px) {
            display: block;
            width: 50%;
          }
          @media (min-width:992px) {
            width: 30%;
          }
          h3 {
            font-size: 1.8rem;
            line-height: 2rem;
            display: block;
            color: var(--accent);
            @media (min-width:768px) {
              font-size: 1.625rem;
            }
          }
          
        }
        .badge-seal-wrapper {
          flex: 0 0 auto;
          width: 100%;
          display: flex;
          //flex-wrap: wrap;
          align-items: center;
          column-gap: .5rem;
          @media (min-width:768px) {
            width: 50%;
          }
          @media (min-width:992px) {
            width: 70%;
            img {
              padding-right: 3.125rem;
            }
          }
        }
      }
    }
  }
  .stats-section {
    padding: 1.875rem 0 0 0;
    @media (min-width:768px) {
      padding: 5rem 0;
    }
    @media (min-width:992px) {
      padding-bottom: 0;
    }
    .container {
      .row {
        .stats-top {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .stats-top-left {
            width: 100%;
            flex: 0 0 auto;
            @media (min-width:992px) {
              width: 50%;
            }
            h2 {
              color: var(--accent);
              @media (min-width:768px) {
                font-size:2.75rem;
                line-height: 3.3rem;
              }
            }
          }
          .stats-top-right {
            width: 100%;
            flex: 0 0 auto;
            margin-top: .625rem;
            @media (min-width:992px) {
              width: 50%;
            }
            p {
              color: #0101018F;
              font-size: 1rem;
            }
          }
        }
        .stats-bottom-counter {
          padding-top: 2.5rem;
          @media (min-width:768px) {
            border-top: rgba(14, 11, 61, 0.08) solid 1px;
            margin-top: 2.5rem;
          }
          @media (min-width:992px) {
            border-bottom: rgba(14, 11, 61, 0.08) solid 1px;
            padding-bottom: 2.5rem;
          }
          .stat-counter-wrapper {
            display: flex;
            flex-wrap: wrap;
            @media (min-width:768px) {
              column-gap: 2rem;
              width: 100%;
            }
            @media (min-width:992px) {
              column-gap: 1rem;
            }
            .stat-item {
              flex: 0 0 auto;
              width: 100%;
              margin-bottom: 1.25rem;
              @media (min-width:768px) {
                width: 45%;
              }
              @media (min-width:992px) {
                width: 23.5%;
              }
              .stat-number {
                font-weight: bold;
                color: var(--accent2);
                font-size: 2rem;
                @media (min-width:768px) {
                  font-size: 3.5rem;
                }
                @media (min-width:992px) {
                  font-size: 2.8rem;
                  margin-bottom: 0;
                }
              }
              .stat-label {
                color: #0101018F;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .section1 {
    background-color: #fff;
    padding: 1.875rem 0;
    @media (min-width:768px) {
      padding: 5rem 0;
    }
    .section-1-content {
      flex: 0 0 auto;
      width: 100%;
      h2 {
        font-size: 1.8rem;
        line-height: 2rem;
        display: block;
        color: var(--accent);
        @media (min-width:768px) {
          font-size: 2.75rem;
          line-height: 3.3rem;
        }
        @media (min-width:992px) {
          max-width: 958px;
          text-align: center;
          margin: 0 auto;
        }
      }
      p {
        margin-top: 10px;
        color: #0101018F;
        font-size: 1rem;
        @media (min-width:992px) {
          max-width: 958px;
          text-align: center;
          margin: 2rem auto 0 auto;
        }
      }
      .item-benefits {
        margin: 2rem 0;
        //padding: 0 1rem;
        ul {
          display: flex;
          flex-wrap: wrap;
          @media (min-width:992px) {
            column-gap: 1rem;
          }
          li {
            flex: 0 0 auto;
            margin-bottom: 1rem;
            width: 100%;
            margin-top: 1.25rem;
            @media (min-width:992px) {
              width: 48%;
            }
            .benefit {
              background-color: #EEF7FE;
              border-radius: 5px;
              padding-top: 2rem;
              position: relative;
              overflow: hidden;
              .benefit-content {
                padding: 0 2rem;
                margin-bottom: 1.25rem;
                width: 100%;
                flex: 0 0 auto;
                h2 {
                  font-size: 1.3rem;
                  color: var(--accent);
                  font-weight: 500;
                  @media (min-width:768px) {
                    font-size: 1.625rem;
                    line-height: 2.275rem;
                  }
                  @media (min-width:992px) {
                    text-align: left;
                  }
                }
                p {
                  margin-top: 10px;
                  color: #0101018F;
                  font-size: 1rem;
                  line-height: 1.7rem;
                  @media (min-width:992px) {
                    text-align: left;
                  }
                }
              }
              .benefit-image {
                text-align: center;
                @media (min-width:992px) {
                  max-height: 188px;
                }
              }
            }
            img {
              text-align: center;
            }
          }
        }
      }
      .cta-holder {
        max-width: 580px;
        margin: 1rem auto;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-size: 1.5rem;
          font-weight: 700;
          background-color: var(--accent2);
          color: #fff;
          padding: 0.5rem 1rem;
          border-radius: 100px;
          img {
            max-width: 40px;
            margin-left: 0.5rem;
          }
          transition: all 0.2s ease-in-out;
          &:hover,
          &:focus {
            background-color: var(--darkaccent2);
          }
        }
        span {
          font-size: 1rem;
        }
      }
    }
  }
  .testimonial {
    padding: 1.875rem 0;
    background-color: #EEF7FE;
    @media (min-width:768px) {
      padding: 5rem 0;
    }
    .container {
      .row {
        .testimonial-headline {
          flex: 0 0 auto;
          width: 100%;
          @media (min-width:992px) {
            width: 50%;
          }
          h2 {
            font-size: 1.8rem;
            line-height: 2rem;
            display: block;
            color: var(--accent);
            margin-bottom: 1.25rem;
            @media (min-width:768px) {
              font-size: 2.75rem;
              line-height: 3.3rem;
            }
          }
        }
        .testimonial-content-holder {
          flex: 0 0 auto;
          width: 100%;
          p {
            margin-top: 10px;
            color: #0101018F;
            font-size: 1rem;
          }
        }
        .testimonial-list {
          display: flex;
          margin-top: 2rem;
          flex-wrap: wrap;
          @media (min-width:768px) {
            column-gap: 1rem;
          }
          .testimonial-item {
            margin-bottom: 1rem;
            flex: 0 0 auto;
            width: 100%;
            margin-bottom: 1rem;
            .testimonial-inner {
              background-color: #fff;
              padding: .625rem 2.5rem 2.5rem 2.5rem;
              text-align: left;
              box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0);
              border-radius: 10px 10px 10px 10px;
              .testimonial-bottom {
                margin: 1.875rem 0;
                p {
                  color: #0101018F;
                  font-size: 15px;
                  line-height: 25.5px;
                }
                h3 {
                  margin-bottom: 0.5rem;
                }
              }
              .star-rating {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                img {
                  max-width: 125px;
                }
              }
              .testimonial-top {
                display: flex;
                justify-content: space-between;
                .testimonial-top-wrapper {
                  align-items: center;
                  display: flex;
                  justify-content: space-between;
                  .testimonial-left {
                    display: inline-flex;
                    img {
                      margin-right: 20px;
                      margin-bottom: 0;
                      border-radius: 50%;
                      max-width: 100%;
                      width: 60px;
                      height: auto;
                    }
                  }
                  .testimonial-right {
                    text-align: left;
                    h3 {
                      font-size: 1rem;
                      line-height: 1.6rem;
                      font-weight: 500;
                    }
                    p {
                      font-size: 13px;
                      line-height: 1.375rem;
                      color: #0101018F;
                    }
                  }
                }
              }
            }
            @media (min-width:768px) {
              width: 48%;
            }
          }
        }
      }
    }
  }
}

.error {
  color: red !important;
}
